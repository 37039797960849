import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Acid from "../../images/media/products/acid/acid.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"






const Product = () => (
    <Layout>
      <Seo title="Odnawiający peeling kwasowy - Linia Acid Peel" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="blue">
                    <H1>Przeciwstarzeniowy <br/>peeling kwasowy </H1>
                    <p>Redukcja zmarszczek i odbudowa</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="przeciwstarzeniowy peeling"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Ujędrnia na poziomie komórkowym. </li>
                        <li>Redukuje nawet głębokie zmarszczki. </li>
                        <li>Odbudowuje i wyrównuje strukturę skóry. </li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek.</li>
                    </ul>
                    <Container>
                        <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-przeciwstarzeniowy-peeling-kwasowy-do-twarzy-50-ml-000000000000407505.html" color="blue">Kup w Hebe</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-acid-peel-przeciwstarzeniowy-peeling-kwasowy-50-ml.html" color="blue">Kup w Ziko Dermo</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-acid-peel-aha-pha-przeciwstarzeniowy-peeling-kwasowy-50-ml-0107783" color="blue">Kup w Gemini</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="przeciwstarzeniowy peeling"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="blue" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar>
                    <Link to="/produkty/odnawiajacy-peeling">
                        <StaticImage
                            src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="Energetyzujące serum"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="blue">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="blue">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>Preparat dzięki zdolności przenikania do głębokich warstw skóry, stymuluje procesy wytwarzania włókien kolagenu i elastyny, co powoduje jej ujędrnienie i uelastycznienie.</strong>
                                </p>
                                <p>
                                    W konsekwencji zmarszczki są wygładzone, a skóra widocznie odmłodzona. Peeling dzięki wspieraniu syntezy ceramidów, intensywnie regeneruje skórę, nawilża i zapobiega transepidermalnej utracie wody (TEWL), chroniąc ją przed odwodnieniem.
                                </p>
                                <p>
                                    Ponadto hamuje produkcję melaniny, widocznie rozjaśniając przebarwienia. Dodatkowo poprzez pobudzenie metabolizmu komórkowego znacznie poprawia kondycję skóry, zapewniając jej młody i promienny wygląd.
                                </p>
                                <p>
                                    Preparat polecany dla skóry wymagającej poprawy sprężystości, redukcji nawet głębokich zmarszczek, ujednolicenia kolorytu i dogłębnego nawilżenia.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="przeciwstarzeniowy peeling"
                                />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>
                                    Nałożyć cienką warstwę peelingu wieczorem na skórę twarzy, szyi i dekoltu. Stosować w formie kuracji przez 3 tygodnie. W pierwszym tygodniu preparat aplikować co drugi dzień. Następnie stosować codziennie, aż do zakończenia kuracji. Dla podtrzymania efektu stosować 1-2 razy w tygodniu. Podczas kuracji używać filtrów UV oraz unikać ekspozycji na słońce. Unikać bezpośredniego kontaktu z oczami.
                                </p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>50ml</p>
                                <span className="acid-peel">
                                    <Acid/>
                                </span>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Retinol z adenozyną" desc="Składniki te pobudzają produkcję kolagenu i elastyny, ujędrniając i uelastyczniając skórę, spłycając głębokie zmarszczki i chroniąc przed ich powstawaniem. Przyspieszają regenerację skóry, usuwając stare komórki i zastępując je nowymi. Dodatkowo rozjaśniają przebarwienia, zmniejszają wielkość porów i regulują wydzielanie sebum." />
                    <Ingredient name="Kompleks roślinnych kwasów AHA" desc="To kompleks 5 kwasów AHA (cytrynowy, winowy, mlekowy, jabłkowy i glikolowy) o silnym działaniu aktywującym procesy naprawcze w skórze. Intensywnie złuszcza naskórek, stymuluje regenerację komórkową oraz wygładza strukturę skóry." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                    <Ingredient name="Glukonolakton" desc="Kwas PHA o silnych właściwościach nawilżających, pomaga utrzymać prawidłowy bilans nawodnienia skóry. Jest ważnym czynnikiem w procesie odnowy komórki i naturalnym antyoksydantem skutecznie zwalczającym wolne rodniki oraz wzmacniającym mechanizmy naprawcze i ochronne skóry przed skutkami promieniowania ultrafioletowego. Wpływa hamująco na procesy starzenia się skóry, poprawia fakturę, ujędrnia i wygładza." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Kwas hialuronowy" desc="Składnik o działaniu silnie nawilżającym i hamującym procesy starzenia. Tworzy warstwę okluzyjną na skórze, zmniejszając transepidermalną utratę wody (TEWL)." />
                    <ActiveIngredient name="Panthenol" desc="Prowitamina B5, która przenikając do głębszych warstw skóry, nawilża ją i łagodzi podrażnienia. Dodatkowo redukuje transepidermalną utratę wody (TEWL) i przywraca równowagę hydrolipidową." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">
                <Effectivness>
                    <EffectivnessCard color="blue" percentage="80" desc="badanych potwierdza, że produkt nawilża i odżywia skórę"/>
                    <EffectivnessCard color="blue" percentage="75" desc="badanych potwierdza, że po zastosowaniu skóra staje się jędrna i elastyczna"/>
                    <EffectivnessCard color="blue" percentage="70" desc="badanych potwierdza, że produkt zmniejsza widoczność przebarwień"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
